// ==================================================
//  Event: モバイル判定
// ==================================================
let vw = window.innerWidth;
const breakpointSP = 767;
const isMobile = (vw) => {
  if (breakpointSP >= vw) {
    return true;
  } else {
    return false;
  }
}

// ==================================================
//  Event: メインビジュアル
// ==================================================
const mainvisual = document.querySelector(".js_mainvisual");
document.addEventListener('DOMContentLoaded', (event) => {
  const mainvisualLoadedAnimation = () => {
    mainvisual.classList.add('js_mainvisual-active');
  }

  setTimeout(mainvisualLoadedAnimation, 0.5*1000);
});


// ==================================================
//  Function: #mainvisual に max-height を設定
// ==================================================
const mainvisualHeightSet = () => {
  const vh = window.innerHeight;
  const mainvisualSP = document.querySelector(".mainvisual");
  mainvisualSP.style.maxHeight = (vh - 48) + 'px';
}
document.addEventListener('DOMContentLoaded', (event) => {
  if (isMobile(vw)) {
    mainvisualHeightSet();
  }
});